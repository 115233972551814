<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div>
      <v-row>
        <v-col
          v-if="!isEmpty(userData.searchType)"
          cols="12"
          md="6"
        >
        *Tan Phu Entrance data is updated every 1 hour. Other malls are every 10 minutes.
          <v-simple-table
            dense
            class="tableCss"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    style="width:10%;"
                  >
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Tan Phu
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Binh Duong
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Long Bien
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Binh Tan
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Ha Dong
                  </th>
                  <th
                    class="text-center"
                    style="width:15%;"
                  >
                    Le Chan
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.comparisonData"
                  :key="item.time"
                  :class="getClass(item.time, index)"
                >
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right">
                    {{ item.TanPhu }}
                  </td>
                  <td class="text-right">
                    {{ item.BinhDuong }}
                  </td>
                  <td class="text-right">
                    {{ item.LongBien }}
                  </td>
                  <td class="text-right">
                    {{ item.BinhTan }}
                  </td>
                  <td class="text-right">
                    {{ item.HaDong }}
                  </td>
                  <td class="text-right">
                    {{ item.LeChan }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  v-for="item in userData.comparisonFooterData"
                  :key="item.time"
                  :class="item.time === 'Total' ? 'yellow': ''"
                >
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right">
                    {{ item.TanPhu }}
                  </td>
                  <td class="text-right">
                    {{ item.BinhDuong }}
                  </td>
                  <td class="text-right">
                    {{ item.LongBien }}
                  </td>
                  <td class="text-right">
                    {{ item.BinhTan }}
                  </td>
                  <td class="text-right">
                    {{ item.HaDong }}
                  </td>
                  <td class="text-right">
                    {{ item.LeChan }}
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <!-- excel出力用 -->
    <div
      id="mainTable"
      hidden
      data-cols-width="20,15,15,15,15,15,15"
    >
      <v-row>
        <v-col
          v-if="!isEmpty(userData.searchType)"
          cols="12"
          md="12"
        >
          <table><tr></tr></table>
          <v-simple-table
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Tan Phu
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Binh Duong
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Long Bien
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Binh Tan
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Ha Dong
                  </th>
                  <th
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    Le Chan
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.comparisonData"
                  :key="item.time"
                >
                  <td
                    v-if="userData.searchType === 2"
                    data-b-a-s="thin"
                    :data-fill-color="getColor(item.time, index)"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    v-if="userData.searchType === 1"
                    data-a-h="center"
                    data-b-a-s="thin"
                    :data-fill-color="getColor(item.time, index)"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.TanPhu === '0' ? '0':'###,###'"
                  >
                    {{ String(item.TanPhu).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.BinhDuong === '0' ? '0':'###,###'"
                  >
                    {{ String(item.BinhDuong).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.LongBien === '0' ? '0':'###,###'"
                  >
                    {{ String(item.LongBien).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.BinhTan === '0' ? '0':'###,###'"
                  >
                    {{ String(item.BinhTan).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.HaDong === '0' ? '0':'###,###'"
                  >
                    {{ String(item.HaDong).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    :data-fill-color="getColor(item.time, index)"
                    :data-num-fmt="item.LeChan === '0' ? '0':'###,###'"
                  >
                    {{ String(item.LeChan).replace(',','').replace(',','') }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  v-for="item in userData.comparisonFooterData"
                  :key="item.time"
                  :class="item.time === 'Total' ? 'yellow': ''"
                >
                  <td
                    data-a-h="center"
                    data-b-a-s="thin"
                    data-fill-color="ffff88"
                  >
                    {{ item.time }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.TanPhu === 0 ? '0':'###,###'"
                  >
                    {{ String(item.TanPhu).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.BinhDuong === 0 ? '0':'###,###'"
                  >
                    {{ String(item.BinhDuong).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.LongBien === 0 ? '0':'###,###'"
                  >
                    {{ String(item.LongBien).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.BinhTan === 0 ? '0':'###,###'"
                  >
                    {{ String(item.BinhTan).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.HaDong === 0 ? '0':'###,###'"
                  >
                    {{ String(item.HaDong).replace(',','').replace(',','') }}
                  </td>
                  <td
                    data-b-a-s="thin"
                    data-t="n"
                    data-fill-color="ffff88"
                    :data-num-fmt="item.LeChan === 0 ? '0':'###,###'"
                  >
                    {{ String(item.LeChan).replace(',','').replace(',','') }}
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <div>
      <br>
      <v-btn
        v-if="!loading"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </div>
    <br>
    <div
      ref="barChart"
      :style="{ width: '100%', height: '400px'}"
    ></div>
    <br>
    <div
      ref="lineChart"
      :style="{ width: '100%', height: '400px'}"
    ></div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-if="userData.searchType === 2"
          v-model="countDisplayswitch"
          flat
          :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay"
        ></v-switch>
    </div>
    <br>
    <div
      v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
      :style="{ width: '100%'}"
    >
      <v-col
        v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
        cols="12"
        md="12"
      >
        <v-simple-table
          dense
          class="tableCss"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  rowspan="2"
                  class="text-center"
                >
                </th>
                <th
                  colspan="3"
                  class="text-center"
                  style="border-right: 1px solid #000;"
                >
                  Tan Phu
                </th>
                <th
                  colspan="3"
                  class="text-center"
                  style="border-right: 1px solid #000;"
                >
                  Binh Duong
                </th>
                <th
                  colspan="3"
                  class="text-center"
                  style="border-right: 1px solid #000;"
                >
                  Long Bien
                </th>
                <th
                  colspan="3"
                  class="text-center"
                  style="border-right: 1px solid #000;"
                >
                  Binh Tan
                </th>
                <th
                  colspan="3"
                  class="text-center"
                  style="border-right: 1px solid #000;"
                >
                  Ha Dong
                </th>
                <th
                  colspan="3"
                  class="text-center"
                >
                  Le Chan
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;border-right: 1px solid #000;"
                >
                  Same<br>Week
                </th>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;border-right: 1px solid #000;"
                >
                  Same<br>Week
                </th>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;border-right: 1px solid #000;"
                >
                  Same<br>Week
                </th>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;border-right: 1px solid #000;"
                >
                  Same<br>Week
                </th>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;border-right: 1px solid #000;"
                >
                  Same<br>Week
                </th>
                <th
                  class="text-center"
                >
                  Current<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Date
                </th>
                <th
                  class="text-center"
                  style="background-color:#ffff88 !important;"
                >
                  Same<br>Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.comparisonMonthly2Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.TanPhu_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.TanPhu_SameDate }}
                </td>
                <td
                  :class="getLastYearSameWeekClass(index)"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.TanPhu_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.BinhDuong_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.BinhDuong_SameDate }}
                </td>
                <td
                  :class="getLastYearSameWeekClass(index)"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.BinhDuong_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.LongBien_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.LongBien_SameDate }}
                </td>
                <td
                  :class="getLastYearSameWeekClass(index)"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.LongBien_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.BinhTan_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.BinhTan_SameDate }}
                </td>
                <td
                  :class="getLastYearSameWeekClass(index)"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.BinhTan_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.HaDong_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.HaDong_SameDate }}
                </td>
                <td
                  :class="getLastYearSameWeekClass(index)"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.HaDong_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.LeChan_CurrentDate }}
                </td>
                <td :class="getLastYearSameDateClass(index)">
                  {{ item.LeChan_SameDate }}
                </td>
                <td :class="getLastYearSameWeekClass(index)">
                  {{ item.LeChan_SameWeek }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.comparisonMonthly2FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.TanPhu_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.TanPhu_SameDate }}
                </td>
                <td
                  class="text-right"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.TanPhu_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.BinhDuong_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.BinhDuong_SameDate }}
                </td>
                <td
                  class="text-right"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.BinhDuong_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.LongBien_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.LongBien_SameDate }}
                </td>
                <td
                  class="text-right"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.LongBien_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.BinhTan_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.BinhTan_SameDate }}
                </td>
                <td
                  class="text-right"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.BinhTan_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.HaDong_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.HaDong_SameDate }}
                </td>
                <td
                  class="text-right"
                  style="border-right: 1px solid #000;"
                >
                  {{ item.HaDong_SameWeek }}
                </td>
                <td class="text-right">
                  {{ item.LeChan_CurrentDate }}
                </td>
                <td class="text-right">
                  {{ item.LeChan_SameDate }}
                </td>
                <td class="text-right">
                  {{ item.LeChan_SameWeek }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <!-- excel出力用 -->
    <div
      v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
      id="monthly2Table"
      hidden
      data-cols-width="20"
    >
      <v-col
        v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
        cols="12"
        md="12"
      >
        <table><tr></tr></table>
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  rowspan="2"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Tan Phu
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Binh Duong
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Long Bien
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Binh Tan
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Ha Dong
                </th>
                <th
                  colspan="3"
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Le Chan
                </th>
              </tr>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Current Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Date
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Same Week
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.comparisonMonthly2Data"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.TanPhu_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.TanPhu_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.TanPhu_SameWeek).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.BinhDuong_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.BinhDuong_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.BinhDuong_SameWeek).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LongBien_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.LongBien_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LongBien_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.LongBien_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LongBien_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.LongBien_SameWeek).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.BinhTan_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.BinhTan_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.BinhTan_SameWeek).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.HaDong_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.HaDong_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.HaDong_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.HaDong_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.HaDong_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.HaDong_SameWeek).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LeChan_CurrentDate === '0' ? '0':'###,###'"
                  :data-fill-color="getColor(item.time, index)"
                >
                  {{ String(item.LeChan_CurrentDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LeChan_SameDate === '0' ? '0':'###,###'"
                  :data-fill-color="getSameDataColor(index)"
                >
                  {{ String(item.LeChan_SameDate).replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.LeChan_SameWeek === '0' ? '0':'###,###'"
                  :data-fill-color="getSameWeekColor(index)"
                >
                  {{ String(item.LeChan_SameWeek).replace(',','').replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.comparisonMonthly2FooterData"
                :key="item.time"
              >
                <td
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_CurrentDate === '0' ? '0':'#,###'"
                >
                  {{ String(item.TanPhu_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_SameDate === '0' ? '0':'#,###'"
                >
                  {{ String(item.TanPhu_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.TanPhu_SameWeek === '0' ? '0':'#,###'"
                >
                  {{ String(item.TanPhu_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_CurrentDate === '0' ? '0':'#,###'"
                >
                  {{ String(item.BinhDuong_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_SameDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.BinhDuong_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhDuong_SameWeek === '0' ? '0':'###,###'"
                >
                  {{ String(item.BinhDuong_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LongBien_CurrentDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.LongBien_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LongBien_SameDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.LongBien_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LongBien_SameWeek === '0' ? '0':'###,###'"
                >
                  {{ String(item.LongBien_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_CurrentDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.BinhTan_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_SameDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.BinhTan_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.BinhTan_SameWeek === '0' ? '0':'###,###'"
                >
                  {{ String(item.BinhTan_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.HaDong_CurrentDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.HaDong_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.HaDong_SameDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.HaDong_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.HaDong_SameWeek === '0' ? '0':'###,###'"
                >
                  {{ String(item.HaDong_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LeChan_CurrentDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.LeChan_CurrentDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LeChan_SameDate === '0' ? '0':'###,###'"
                >
                  {{ String(item.LeChan_SameDate).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-t="n"
                  :data-num-fmt="item.LeChan_SameWeek === '0' ? '0':'###,###'"
                >
                  {{ String(item.LeChan_SameWeek).replace(',','').replace(',','').replace(',','').replace(',','') }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <div>
      <br>
      <v-btn
        v-if="userData.searchType === 2"
        color="primary"
        dark
        style="text-transform: none"
        :v-if="!loading"
        @click="generateMonthly2XLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import store from '@/store'
import tableToExcelJs from '@linways/table-to-excel'
import comparisonStoreModule from './comparisonStoreModule'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

const USER_APP_STORE_MODULE_NAME = 'app-comparison'

// Register module
if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
  store.registerModule(USER_APP_STORE_MODULE_NAME, comparisonStoreModule)
}

// UnRegister on leave
onUnmounted(() => {
  if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
})

const userData = ref({})

const echarts = require('echarts')

export default {
  components: { Loading },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawBar(val)
        this.drawLine(val)
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false

    this.$datePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const ecryptedData = sessionStorage.getItem('siteList')

      // 復号する
      const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
      const siteList = JSON.parse(decrypted.toString(crypto.enc.Utf8))

      let siteIds
      siteList.forEach(element => {
        if (isEmpty(siteIds)) {
          siteIds = `${element.id}`
        } else {
          siteIds = `${siteIds},${element.id}`
        }
      })
      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      this.loading = true
      store
        .dispatch('app-comparison/fetchData', {
          params: {
            siteId,
            siteIds,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    // bar chart作成する
    drawBar(val) {
      const barChartOption = {
        title: {
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            splitNumber: 5,
            axisTick: {
              alignWithLabel: true,
            },
            data: val.legendData,
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
          },
        ],
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: [
          {
            name: 'Total',
            type: 'bar',
            barWidth: '45%',
            label: {
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            data: val.seriesData,
          },
        ],
      }

      if (val.searchType === 1) {
        this.$refs.barChart.style.display = 'block'
        let barChart = echarts.getInstanceByDom(this.$refs.barChart)
        if (barChart == null) {
          barChart = echarts.init(this.$refs.barChart)
        }

        barChart.setOption(barChartOption, true)
      } else {
        this.$refs.barChart.style.display = 'none'
      }
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },

    // Line chart作成する
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: val.labelShow,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: newSeriesData,
      }

      if (val.searchType === 2) {
        this.$refs.lineChart.style.display = 'block'
        let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
        if (lineChart == null) {
          lineChart = echarts.init(this.$refs.lineChart)
        }

        lineChart.setOption(lineChartOption, true)
      } else {
        this.$refs.lineChart.style.display = 'none'
      }
    },

    getLastYearSameDateClass(index) {
      let css = 'Normal text-right'
      const time = this.userData.lastLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getLastYearSameWeekClass(index) {
      let css = 'Normal text-right'
      const time = this.userData.lastYearSameWeekData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        css = 'Holiday text-right'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat text-right'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun text-right'
      }

      return css
    },

    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (this.userData.holidayData.indexOf(time) >= 0) {
        css = 'Holiday'
      } else if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },

    getColor(time, index) {
      let color = ''
      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          color = ''
        } else {
          color = 'c0c0c0'
        }
      } else if (this.userData.holidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      } else {
        color = ''
      }

      return color
    },

    getSameDataColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastLegendData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      }

      return color
    },
    getSameWeekColor(index) {
      let color = ''
      if (this.userData.searchType === 1) return color

      const time = this.userData.lastYearSameWeekData[index]
      if (this.userData.lastHolidayData.indexOf(time) >= 0) {
        color = 'FEDCBD'
      } else if (time.indexOf('Sat') >= 0) {
        color = 'ccffff'
      } else if (time.indexOf('Sun') >= 0) {
        color = 'ffccff'
      }

      return color
    },

    /* excelダウンロード */
    generateXLSX: () => {
      tableToExcelJs.convert(document.getElementById('mainTable'), {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    generateMonthly2XLSX: () => {
      tableToExcelJs.convert(document.getElementById('monthly2Table'), {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },
  },
  setup() {
    const countDisplayswitch = ref(true)

    return {
      countDisplayswitch,
      userData,

      /* tableのスタイル、カラムデータ */
      rowStyleOption: {
        stripe: true,
      },
      cellStyleOption: {
        headerCellClass: () => 'table-header-cell-class',
      },
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      isEmpty,
      loading: false,
    }
  },
}
</script>
<style scoped>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
.tableCss th {
  white-space: nowrap;
  padding: 0px 5px !important;
  /* background: #ccffcc !important; */
}
.tableCss td {
  padding: 0px 5px !important;
  font-size: 12px !important;
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid rgba(94, 86, 105, 0.14);
  min-width: 60px;
}
.theme--light.v-data-table th {
  border: 0.5px solid rgba(94, 86, 105, 0.14);
}
.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}
.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.v-data-table tbody .Holiday {
  background-color: #fedcbd;
}
.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}
.v-data-table tbody .Normal {
  background-color: #fff;
}
</style>
